import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-scroll';

const Header = () => {
	return (
		<div id='home' className='header-wraper'>
			<div className='layer'>
				<div className='main-info'>
					<canvas></canvas>
					<h1>web development and custom software</h1>

					<Typed
						className='typed-text'
						strings={['Web Design', 'Custom Software', 'Web Development']}
						typeSpeed={40}
						backSpeed={60}
						loop
					/>
					<Link
						smooth={true}
						to='contacts'
						offset={-110}
						className='btn-main-offer'
					>
						Contact us
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Header;
