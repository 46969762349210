import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faDesktop, faFileCode } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
	return (
		<div id='services' className='services'>
			<h1 className='py-5'>our services</h1>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-3 col-md-6 col-sm-6'>
						<div className='box'>
							<div className='circle'>
								<FontAwesomeIcon className='icon' icon={faDesktop} size='2x' />
							</div>
							<h3>Web Design</h3>
							<p>
								By designing your UI with optimal user experience in mind, your
								website will become a powerful business tool.
							</p>
						</div>
					</div>
					{/* - */}
					<div className='col-lg-3 col-md-6 col-sm-6'>
						<div className='box'>
							<div className='circle'>
								<FontAwesomeIcon className='icon' icon={faFileCode} size='2x' />
							</div>

							<h3>Web Development</h3>
							<p>
								Our team will develop you a new website with modern technologies
								or solve problems within your current website.
							</p>
						</div>
					</div>
					{/* - */}
					<div className='col-lg-3 col-md-6 col-sm-6'>
						<div className='box'>
							<div className='circle'>
								<FontAwesomeIcon
									className='icon'
									icon={faFacebookF}
									size='2x'
								/>
							</div>

							<h3>Facebook Ads SMM</h3>
							<p>
								We will help you in create and post that ads are targeted to
								users based on their location, demographic, and profile
								information.
							</p>
						</div>
					</div>
					{/* - */}
					<div className='col-lg-3 col-md-6 col-sm-6'>
						<div className='box'>
							<div className='circle'>
								<FontAwesomeIcon className='icon' icon={faGoogle} size='2x' />
							</div>
							<h3>Google Ads</h3>
							<p>
								Our ad services will help your website and social media rank on
								the first page of Google! It’s the most profitable way to scale
								a website over the long-term.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Services;
