import React from 'react';
import group from '../group.png';
const About = () => {
	return (
		<div id='about' className='container py-5'>
			<div className='row'>
				<div className='col-lg-6 col-xm-12'>
					<div className='photo-wrap mb-5'>
						<img className='profile-img' src={group} alt='author...' />
					</div>
				</div>
				<div className='col-lg-6 col-xm-12'>
					<h1 className='about-heading'>about</h1>
					<p>
						Wren Mountain Media is your software engineering and web design go
						to. We are ready to overcome your marketing and web development
						challenges for you! Our passion for helping small and medium size
						businesses has grown into a full-service marketing company
						developing online solutions for organizations across multiple
						business sectors.
					</p>
					<p>
						No matter if you are a Small Business, Individual, or Industry our
						Website Design & Marketing Services are For You!
					</p>
				</div>
			</div>
		</div>
	);
};

export default About;
