import React from 'react';
const Footer = () => {
	return (
		<div className='footer'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-4 col-md-6 col-sm-6'>
						<div className='d-flex'>
							<p>Decatur, Alabama</p>
						</div>
						<div className='d-flex'>
							<a href='tel:555-555-555'>(256) 792-6560</a>
						</div>
						<div className='d-flex'>
							<p>wrenmountain@gmail.com</p>
						</div>
					</div>
					<div className='col-lg-3 col-md-2 col-sm-6'>
						<div className='row'>
							<div className='col'>
								<a className='footer-nav'>Home</a>
								<br />
								<a className='footer-nav'>About</a>
								<br />
								<a className='footer-nav'>Services</a>
							</div>
							<div className='col'>
								{/* <a className="footer-nav">Experience</a>
                <br />
                <a className="footer-nav">Portfolio</a>
                <br /> */}
								{/* <a className='footer-nav'>Contact</a> */}
							</div>
						</div>
					</div>
					<div className='col-lg-5 col-md-5 col-sm-6 align-items-center'>
						<p className='pt-3 text-center'>
							Copyright&copy; {new Date().getFullYear()}&nbsp;Wren Mountain
							Media | All Rights Reserved
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
