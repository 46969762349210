import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import AboutMe from './components/About';
import Services from './components/Services';
// import OurWork from './components/OurWork';
// import Contacts from './components/Contacts';
import Footer from './components/Footer';

function App() {
	return (
		<>
			<Navbar />
			<Header />
			<Services />
			{/* <OurWork /> */}
			<AboutMe />
			{/* <Contacts /> */}
			<Footer />
		</>
	);
}

export default App;

// Red Highlight: #B73225

// Blue Minded: #004E7C

// Maroon 6: #591COB

// Grey Water: #5C5F58

// Lighter Grey: #DCE1E3
